import styled from 'styled-components'

import bg from '../../images/login_bg.svg'

export const LoginMain = styled.div`
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-image:url(${bg});
    background-position:center center;
    background-size:cover;

    .login_header{
        margin-bottom:30px;
    }

`

export const FormmRow = styled.div`
    width:300px;
    height:50px;

    button{
        width:100%;
    }

`