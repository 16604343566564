import { withRouter } from 'react-router-dom'
import axios from 'axios'

/**
 * 处理用户登录组件
 */

const Is_login = props => {
    axios.post('/api/new/is_login').then(res => {
        const { data } = res
        const { pathname } = props.location
        if (data.ecode === 0 && pathname === '/login') {
            props.history.replace('/')
        } else if (data.ecode !== 0 && pathname !== '/login') {
            props.history.replace('/login')
        }
    }).catch(err => {
        const { pathname } = props.location
        if (pathname !== '/login') {
            props.history.replace('/login')
        }
    })
    return ''
}

export default withRouter(Is_login)