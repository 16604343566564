/**
 * 传统的富文本编辑器模式添加文章
 * 富文本开发文档地址：https://www.yuque.com/braft-editor/be/lzwpnr
 * https://braft.margox.cn/demos/basic
 * https://www.jianshu.com/p/acb0fc96b259
 */
import React, { Component } from 'react'
import { Button, Input, Form, Upload, message, Spin } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { Main } from './style'
import Axios from 'axios'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

class DefaultCreate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editorState: '',
            formData: {
                logo: '',
                title: '',
                context: '',
                describe: ''
            },
            logoLoading: false,
            loading: true
        }
        this.formRef = React.createRef()
    }

    render() {
        const layout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 22 },
            labelAlign: 'right'
        };
        const tailLayout = {
            wrapperCol: { offset: 2, span: 2 },
        };

        const uploadButton = (
            <div>
                {this.state.logoLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>点击上传封面</div>
            </div>
        );

        return (
            <Main>
                {
                    this.state.loading && (
                        <div className="example">
                            <Spin size="large" />
                        </div>
                    )
                }
                <Form
                    {...layout}
                    ref={this.formRef}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        label="文章标题"
                        name="title"
                        rules={[{ required: true, message: '请输入文章标题' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="文章封面"
                        name="logo"
                        rules={[{ required: true, message: '请上传文章封面' }]}
                    >
                        <Upload
                            name="img"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="/api/new/uploadImage"
                            beforeUpload={this.handleUploadBefore}
                            onChange={this.handleUploadChange}
                        >
                            {this.state.formData.logo ? <img src={this.state.formData.logo} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="文章描述"
                        name="describe"
                        rules={[{ required: true, message: '请输入文章描述' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        label="文章详情"
                        name="context"
                    >
                        <div className="my-component">
                            <BraftEditor
                                value={this.state.editorState}
                                onChange={this.handleEditorChange}
                                onSave={this.submitContent}
                                media={{uploadFn: this.handleEditorUploadMedia}}
                                placeholder="请输入文章详情"
                            />
                        </div>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            保存文章
                        </Button>
                    </Form.Item>

                </Form>

            </Main>
        )
    }

    // 富文本上传媒体文件
    handleEditorUploadMedia = param => {
        console.log(param);
        const fd = new FormData()
        fd.append('img',param.file)
        Axios.post('/api/new/uploadImage',fd).then(res => {
            console.log(res.data.data.url);

            param.success({
                url:res.data.data.url,
                meta:{
                    loop: true, // 指定音视频是否循环播放
                    autoPlay: true, // 指定音视频是否自动播放
                    controls: true, // 指定音视频是否显示控制栏
                }
            })


        }).catch(err => {
            console.log(err);
        })
    }

    componentDidMount() {
        const { id } = this.props.match.params
        if (id !== '0') {
            Axios.post('/api/new/getArticleInfo', { id }).then(res => {
                const { data } = res.data
                const { formData } = this.state
                formData.logo = data.logo
                formData.id = id
                this.setState({
                    formData,
                    editorState: BraftEditor.createEditorState(data.context),
                    loading: false
                })
                this.formRef.current.setFieldsValue({
                    ...data
                });
            }).catch(err => {
                console.log(err);
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    // 上传封面前检测文件类型
    handleUploadBefore = (file) => {
        const { type } = file
        return type === 'image/png' || type === 'image/jpeg'
    }

    // 上传封面
    handleUploadChange = (file) => {
        if (file.file.status === 'done') {
            const { url } = file.file.response.data
            const newImage = new Image()
            newImage.src = url
            newImage.onload = () => {
                const { formData } = this.state
                formData.logo = file.file.response.data.url
                this.setState({
                    logoLoading: false,
                    formData
                }, () => {
                    message.success('上传成功')
                })
            }
        } else {
            this.setState({
                logoLoading: true
            })
        }
    }


    onFinish = values => {
        const { editorState, formData } = this.state
        if (editorState.toHTML() === '') {
            message.error('请输入文章详情')
            return
        }
        values.context = editorState.toHTML()
        values.logo = formData.logo
        values.ctype = 0
        if(formData.id){
            values.id = formData.id
        }
        Axios.post('/api/new/addArticle', { ...values }).then(res => {
            if (res.data.ecode === 0) {
                message.success('发布成功')
                this.props.history.push('/article')
            } else {
                message.error(res.data.msg)
            }
        }).catch(err => {
            console.log(err);
        })
    };

    onFinishFailed = errorInfo => {
        message.error('请完善表单内容')
    };

    handleEditorChange = editorState => {
        const { formData } = this.state
        formData.context = editorState.toHTML()
        this.setState({ editorState, formData })
    }

    submitContent = () => {
        console.log(this.state.editorState.toHTML());
    }

}

export default DefaultCreate