import Home from './pages/user_conetent/index.jsx'
import Article from './pages/article_list/router'
import {
    UserOutlined,
    BookOutlined
} from '@ant-design/icons';

const routers = [
    {
        path: '/home',
        name: 'Home',
        component: Home,
        icon: <UserOutlined />
    },
    {
        path: '/article',
        name: '文章列表',
        component: Article,
        icon: <BookOutlined />
    }
]

export default routers