import React, { Component, Fragment } from 'react'
import { AddBox, Submit, MinHeight } from './style'
import { Input, Button, message, Spin, Upload, Modal, Image } from 'antd';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import Editor from 'for-editor'
import Axios from 'axios'
import ReactMarkdown from 'react-markdown'

class AddArticle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formData: {
                logo: '',
                context: '',
                title: '',
                type: 1
            },
            context: '',
            loading: true,
            uploadLogoWindow: false,
            imgloading: false
        }
        this.$vm = React.createRef()
    }

    render() {
        const { Dragger } = Upload;
        const props = {
            name: 'img',
            multiple: true,
            action: '/api/new/uploadImage',
            showUploadList: false,
            disabled: this.state.imgloading && this.state.formData.logo === '',
            onChange: this.handleUploadChange,
            beforeUpload: (file) => {
                console.log(this);
                const { type } = file
                let result = type === 'image/png' || type === 'image/jpeg'
                if (result) {
                    this.setState({
                        imgloading: true
                    })
                }
                return result
            }
        };
        return (
            <AddBox>
                {
                    this.state.loading && (
                        <div className="example">
                            <Spin size="large" />
                        </div>
                    )
                }
                <Input value={this.state.formData.title} onChange={this.handleTitleChange} placeholder="请输入标题" />
                <Editor
                    ref={this.$vm}
                    value={this.state.context}
                    onChange={value => { this.setState({ context: value, set: true }) }}
                    onSave={this.onSaveContext}
                    addImg={($file) => this.addImg($file)}
                />
                <Submit>
                    <Button onClick={this.formPostomSend} type="primary">发布</Button>
                    <Button style={{ marginLeft: '16px' }} onClick={this.setArticleLogo} type="primary">添加封面</Button>
                </Submit>
                <div ref="markdown" style={{ display: 'none' }}>
                    <ReactMarkdown source={this.state.formData.context} />
                </div>

                <Modal title="上传封面" visible={this.state.uploadLogoWindow} onOk={this.setArticleLogo} onCancel={this.setArticleLogo}>
                    <Dragger {...props}>
                        <MinHeight>
                            {
                                this.state.imgloading ? this.handleRenderUploadContent() :
                                    (
                                        <Fragment>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">单击或拖拽到此区域进行上传</p>
                                            <p className="ant-upload-hint">
                                                只支持png|jpg格式文件
                                        </p>
                                        </Fragment>
                                    )
                            }
                        </MinHeight>
                    </Dragger>
                </Modal>
            </AddBox>
        )
    }

    addImg = (file) => {
        const fd = new FormData()
        fd.append('img', file)
        Axios.post('/api/new/uploadImage', fd).then(res => {
            this.$vm.current.$img2Url(file.name, res.data.data.url)
        }).catch(err => {
            console.log(err);
        })
    }

    // 上传时钩子函数
    handleUploadChange = info => {
        const { status } = info.file;
        if (status === 'done') {
            const { data } = info.file.response
            const { formData } = this.state
            formData.logo = data.url
            this.setState({
                formData
            })
            message.success('上传成功');
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    // 渲染上传组件显示内容
    handleRenderUploadContent = () => {
        if (this.state.formData.logo === '') {
            return <LoadingOutlined />
        } else {
            return (
                <Image
                    width={200}
                    src={this.state.formData.logo}
                />
            )
        }
    }

    // 打开上传封面弹窗
    setArticleLogo = () => {
        this.setState({
            uploadLogoWindow: !this.state.uploadLogoWindow
        })
    }

    componentDidMount() {
        const { id } = this.props.match.params
        if (id !== '0') {
            Axios.post('/api/new/getArticleInfo', { id }).then(res => {
                const { data } = res.data
                this.setState({
                    formData: { ...data, id },
                    context: data.context,
                    loading: false,
                    imgloading: data.logo !== ''
                })
            }).catch(err => {
                console.log(err);
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    // 标题修改
    handleTitleChange = e => {
        const { value } = e.target;
        const { formData } = this.state
        formData.title = value
        this.setState({
            formData
        })
    }

    // 发布文章
    formPostomSend = () => {
        const { formData, set } = this.state
        if (formData.context === '' && this.state.context === '') {
            message.error('请输入文章详情')
            return
        }
        if (set) {
            message.error('文章详情尚未保存，请先保存')
            return
        }
        formData.type = 2
        this.setState({
            formData
        }, () => {
            this.handleUploadArticle()
        })
    }

    // 保存文章
    onSaveContext = value => {
        const { formData } = this.state
        formData.context = value
        formData.type = 1
        this.setState({
            formData,
            set: false
        }, () => {
            this.handleUploadArticle()
        })
    }

    // 同步文章数据库
    handleUploadArticle = () => {
        const { formData } = this.state
        formData.ctype = 1
        if (formData.title === '') {
            message.error('请输入标题，否则将无法保存文章数据')
            return
        }
        formData.describe = this.refs.markdown.innerText
        Axios.post('/api/new/addArticle', {
            ...formData
        }).then(res => {
            if (res.data.ecode === 0) {
                if (formData.type === 1) {
                    message.success('保存成功')
                } else {
                    message.success('发布成功')
                    this.props.history.push('/article')
                }
                if (!formData.id) {
                    formData.id = res.data.data.id
                    this.setState({
                        formData
                    })
                }
            } else {
                message.error('保存失败，请稍后再试!')
            }
        }).catch(err => {
            console.log(err);
        })
    }

}

export default AddArticle