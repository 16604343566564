/**
 * 负责文章列表与文章添加/编辑组件的切换
 */

import React from 'react'
import { Route } from 'react-router-dom'
import ArticleList from './index.jsx'
import AddArticle from '../add_article/add_article.jsx'
import DefaultCreate from '../add_article_default/index.jsx'

const Article = () => {
    return (
        <div>
            <Route path='/article' exact component={ArticleList}></Route>
            <Route path='/article/add/:id' component={AddArticle}></Route>
            <Route path="/article/add_default/:id" component={DefaultCreate}></Route>
        </div>
    )
}

export default Article