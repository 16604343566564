import React, { Component } from 'react'
import { Header, AList, PageBox, EditorItem } from './style'
import { Input, Button, List, Avatar, Switch, Spin, message, Popconfirm, Pagination, Modal } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons';
import Axios from 'axios';

import { Link } from 'react-router-dom'

const { Search } = Input

class ArticleList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            total: 0,
            formData: {
                length: 10,
                page: 1,
                key: ''
            },
            loading: true,
            delState: {
                confirmLoading: false,
                id: ''
            },
            pathname: props.location.pathname,
            showAddType: false
        }
    }
    render() {
        const IconFont = createFromIconfontCN({
            scriptUrl: [
                "//at.alicdn.com/t/font_2298580_1bun8jrbdad.js"
            ],
        });
        return (
            <div>
                <div>
                    <Header>
                        <div className="search">
                            <Search placeholder="输入文章标题" value={this.state.formData.key} onSearch={this.handleSearchArticle} onChange={this.handleFormKeyChange} enterButton />
                        </div>
                        <div className="add">
                            <Button type="primary" onClick={this.addArticleSelect}>添加</Button>
                        </div>
                    </Header>

                    <AList>
                        {
                            this.state.loading && (<div className="loading">
                                <Spin tip="Loading..." />
                            </div>)
                        }
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.list}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.logo} />}
                                        title={
                                            item.is_show === 1 ? <a href={'http://xue0413.com/detail/' + item.id} target="_blank">{item.title}</a> : <a>{item.title}</a>
                                        }
                                        description={item.describe}
                                    />
                                    <Switch onChange={(e) => this.handleShowArticle(e, item.id)} checkedChildren="显示" unCheckedChildren="隐藏" defaultChecked={item.is_show === 1} checked={item.is_show === 1} />
                                    <Button style={{ marginRight: '10px', marginLeft: '10px' }} type="primary" onClick={() => this.handleGoToArticleSet(item)}>编辑</Button>
                                    <Popconfirm
                                        title="这么棒的文章确定要删除吗？"
                                        okText="想好了"
                                        placement="topRight"
                                        okButtonProps={{ loading: this.state.delState.confirmLoading }}
                                        visible={item.show}
                                        cancelText="我再想想"
                                        onConfirm={this.handleConfirmDel}
                                        onCancel={() => this.handleHideConfirm(item.id)}
                                    >
                                        <Button type="primary" danger onClick={() => { this.handleDelArticleItem(item.id) }}>删除</Button>
                                    </Popconfirm>
                                </List.Item>
                            )}
                        />
                    </AList>
                    <PageBox>
                        <Pagination hideOnSinglePage current={this.state.formData.page} onChange={this.handleChangePages} total={this.state.total} />
                    </PageBox>

                    <Modal title="选择编辑器类型" centered footer={null} visible={this.state.showAddType} onCancel={this.addArticleSelect}>
                        <EditorItem>
                            <Link to="/article/add/0">
                                <div className="edito-item">
                                    <IconFont className="edito-icon" type="icon-markdown-line" />
                                    <span className="edito-name">markdown编辑器</span>
                                    <span className="edito-context">简单快捷(推荐使用)</span>
                                </div>
                            </Link>
                            <Link to="/article/add_default/0">
                                <div className="edito-item">
                                    <IconFont className="edito-icon" type="icon-bianjiqi" />
                                    <span className="edito-name">传统编辑器</span>
                                    <span className="edito-context">功能更多，强大方便</span>
                                </div>
                            </Link>
                        </EditorItem>
                    </Modal>

                </div>
            </div>
        )
    }

    componentDidMount = () => {
        this.handleGetArticleList()
    }

    // 选择新建文章类型
    addArticleSelect = () => {
        this.setState({
            showAddType: !this.state.showAddType
        })
    }

    // 前往文章编辑
    handleGoToArticleSet = obj => {
        if (obj.ctype === 0) {
            this.props.history.push('/article/add_default/' + obj.id)
        } else {
            this.props.history.push('/article/add/' + obj.id)
        }
    }

    // 分页功能
    handleChangePages = e => {
        const { formData } = this.state
        formData.page = e
        this.setState({
            formData,
            loading: true
        }, () => {
            this.handleGetArticleList()
        })
    }

    // 取消删除
    handleHideConfirm = id => {
        let { list } = this.state
        list = list.map(item => {
            if (item.id === id) item.show = false
            return item
        })
        this.setState({
            list
        })
    }

    // 显示||隐藏文章
    handleShowArticle = (e, id) => {
        let { list } = this.state
        list = list.map(item => {
            if (id === item.id) {
                item.is_show = e ? 1 : 0
            }
            return item
        })
        this.setState({
            list
        })

        Axios.post('api/new/updateArticleState', {
            id,
            act: 1,
            show: e ? 1 : 0,
        }).then(res => {
            if (res.data.ecode === 0) {
                message.success('操作成功')
            } else {
                list = list.map(item => {
                    if (id === item.id) {
                        item.is_show = !e ? 1 : 0
                    }
                    return item
                })
                this.setState({
                    list
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    // 删除文章
    handleDelArticleItem = id => {
        let { list, delState } = this.state
        list = list.map(item => {
            if (item.id === id) {
                item.show = !item.show
            } else {
                item.show = false
            }
            return item
        })
        delState.id = id
        this.setState({
            list,
            delState
        })
    }

    // 确认删除文章
    handleConfirmDel = () => {
        let { delState, list } = this.state
        delState.confirmLoading = true
        this.setState({
            delState
        }, () => {
            Axios.post('api/new/updateArticleState', {
                id: delState.id,
                act: 2
            }).then(res => {
                if (res.data.ecode === 0) {
                    list = list.filter(item => item.id !== delState.id)
                    delState.confirmLoading = false
                    this.setState({
                        list,
                        delState
                    }, () => {
                        message.success('删除成功')
                        this.handleGetArticleList()
                    })
                }
            }).catch(err => {
                console.log(err);
            })
        })
    }

    // 搜索关键字修改
    handleFormKeyChange = e => {
        const { value } = e.target
        const { formData } = this.state
        formData.key = value
        this.setState({
            formData
        })
    }

    // 搜索文章
    handleSearchArticle = () => {
        const { formData } = this.state
        formData.page = 1
        this.setState({
            loading: true,
            formData
        }, () => {
            this.handleGetArticleList()
        })
    }

    // 获取文章列表
    handleGetArticleList = () => {
        Axios.post('/api/new/getArticleList', {
            ...this.state.formData
        }).then(res => {
            const { data } = res.data
            this.setState({
                list: data.list,
                total: data.count
            }, () => {
                console.log(this.state.list);
                this.setState({
                    loading: false
                })
            })
        }).catch(err => {
            console.log(err);
        })
    }

}

export default ArticleList