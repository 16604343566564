import styled from 'styled-components'

export const AddBox = styled.div`
    position:relative;
    input{
        margin-bottom:20px
    }
    .example{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:9999999;
        text-align: center;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        display:flex;
        align-items:center;
        justify-content:center;
    }
`

export const Submit = styled.div`
    padding-top:20px;
`

export const MinHeight = styled.div`
    min-height:200px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
`