import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Menu from './component/menu/menu.jsx'
import Login from './pages/login/index.jsx'
import IsLogin from './component/login/is_login.jsx'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <IsLogin />
        <Switch>
          <Route exact path="/login" component={Login}></Route>
          <Route path="/" component={Menu}></Route>
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
